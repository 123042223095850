var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('transition',{attrs:{"name":"fade-in-up"}},[_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items-per-page":100,"items":_vm.lives,"loading":_vm.loading,"hide-default-footer":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"col-md-4"},[_c('router-link',{staticClass:"btn btn-success font-weight-bold btn-sm",attrs:{"to":'/lives/create'}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Thêm ")],1)],1),_c('div',{staticClass:"col-md-6",staticStyle:{"display":"inherit"}},[_c('FunctionalCalendar',{attrs:{"is-modal":true,"is-auto-closeable":true,"date-format":'dd/mm/yyyy',"is-date-range":true,"is-date-picker":true},on:{"choseDay":_vm.select_date},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.clear_filter_date()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1)])]},proxy:true},{key:"item.Thumbnail",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"width":"50px","height":"50px"},attrs:{"src":item.Thumbnail}})]}},{key:"item.Status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_status_text(item.Status))+" ")]}},{key:"item.Start_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.Start_time * 1000).toLocaleString("vi-VN"))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/lives/chat/" + (item.Id))}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-chat ")])],1),_c('router-link',{attrs:{"to":("/lives/update/" + (item.Id))}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.confirm_delete(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }