<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="row">
                    <div class="col-md-12">
                        <v-data-table
                        :headers="headers"
                        :items-per-page="100"
                        :items="lives"
                        :loading="loading"
                        class="elevation-1"
                        :hide-default-footer="true"
                        >
                            <template v-slot:top>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="col-md-4">
                                        <router-link :to="'/lives/create'" class="btn btn-success font-weight-bold btn-sm">
                                            <v-icon left>mdi-plus</v-icon> Thêm
                                        </router-link>
                                    </div>
                                    
                                    <div class="col-md-6" style="display:inherit;">
                                        <!-- <v-btn text class="mr-2">Hôm nay</v-btn> -->
                                        <FunctionalCalendar
                                        v-model="dateRange"
                                        :is-modal='true'
                                        :is-auto-closeable="true"
                                        :date-format="'dd/mm/yyyy'"
                                        :is-date-range="true"
                                        :is-date-picker="true"
                                        v-on:choseDay="select_date"      
                                    ></FunctionalCalendar>
                                    <v-btn @click="clear_filter_date()" text><v-icon small class="mr-2">mdi-close</v-icon></v-btn>
                                    </div>
                                </div>
                            </template>

                            <template v-slot:item.Thumbnail="{ item }">
                                <img :src="item.Thumbnail" style="width: 50px; height: 50px" />
                            </template>
                            <template v-slot:item.Status="{ item }">
                                {{ get_status_text(item.Status) }}
                            </template>
                            <template v-slot:item.Start_time="{ item }">
                                {{ new Date(item.Start_time * 1000).toLocaleString("vi-VN") }}
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <router-link :to="`/lives/chat/${item.Id}`">
                                    <v-icon small class="mr-2">
                                        mdi-chat
                                    </v-icon>
                                </router-link>
                                <router-link :to="`/lives/update/${item.Id}`">
                                    <v-icon small class="mr-2">
                                        mdi-pencil
                                    </v-icon>
                                </router-link>
                                <v-icon small @click="confirm_delete(item)" >
                                    mdi-delete
                                </v-icon>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";

export default {
    metaInfo: {
        title: 'Livestream'
    },
    data() {
        return {
            loading: false,
            options: {},
            query: '',
            dateRange: {},
            start: this.$route.query.start || "",
            end: this.$route.query.end || "",
            headers: [
                { text: "ID", align: 'start', sortable: false, value: 'Id'},
                { text: "Link", value: 'Fb_link_live', sortable: false },
                { text: "Mô tả", value: 'Description', sortable: false },
                { text: "Thời gian bắt đầu", value: 'Start_time', sortable: false },
                { text: "Trạng thái", value: 'Status', sortable: false },
                { text: "Actions", value: 'actions', sortable: false },
            ],
            status_data : {
                "stt_0": "Sắp tới",
                "stt_1": "Đang live",
                "stt_2": "Kết thúc"
            }
        };
    },
    watch: {

    },
    computed: {
        lives() {
            return this.$store.getters['lives/get_lives'];
        },
    },
    components: {
        
    },
    methods: {
        get_lives() {
            var self    = this;
            self.loading = true;
            const start = this.start || "";
            const end = this.end || "";

            var payload = {
                start: start,
                end: end,
            };

            this.$router.push({
                name: "lives-index",
                query: { start, end }
            }).catch(() => { });

            this.$store.dispatch('lives/get_all', payload).then(() => {
                self.loading = false;
            }).catch(() => {
                self.loading = false;
            });
        },
        confirm_delete(item) {
            var self = this;
            Swal.fire({
                title: `Xoá livestream: #${item.Id}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Có',
                cancelButtonText: 'Không'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.delete_item(item.Id);
                }
            })
        },
        delete_item(id) {
            this.$store.dispatch('lives/delete_live', { id, toast: this.$root.$bvToast }).then(() => {
                this.loading = false;
                this.get_lives();
            }).catch(() => {
                this.loading = false;
            });
        },
        select_date() {
            if(!this.dateRange) return;
            if(!this.dateRange.dateRange) return;
            if(!this.dateRange.dateRange.start || !this.dateRange.dateRange.end) return;

            var start_str = this.dateRange.dateRange.start;
            var end_str = this.dateRange.dateRange.end;

            var start_date = start_str.split("/")[0];
            var start_month = start_str.split("/")[1];
            var start_year = start_str.split("/")[2];
            var end_date = end_str.split("/")[0];
            var end_month = end_str.split("/")[1];
            var end_year = end_str.split("/")[2];

            var start = new Date(`${start_month}/${start_date}/${start_year}`).getTime() / 1000;
            var end = new Date(`${end_month}/${end_date}/${end_year}`).getTime() / 1000;

            this.start = start;
            this.end = end;

            this.get_lives();
        },
        clear_filter_date() {
            this.start = "";
            this.end = "";
            this.dateRange = {};
            this.get_lives();
        },
        get_status_text(status) {
            return this.status_data[`stt_${status}`] || "";
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Livestream", route: "index" }, { title: "Tất cả" } ]);
        this.get_lives();
    }
};
</script>
